import { createStore } from 'vuex';

const getDefaultState = () => {
  return {
    // whether the user MM authenticated or not
    verified: false,

    // the user's currently supplied wallet address
    address: null,

    // the user's PVFD holdings by token ID
    pvfdIds: [],

    // currently saved selections mapped by address
    selections: {}
  };
}

/**
 * @link https://auth0.com/blog/state-management-in-vue-3-applications/
 */
const store = createStore({
  state: getDefaultState(),

  mutations: {
    INITIALIZE_STORE(state) {
      console.log('[INITIALIZE_STORE]');

      const savedState = localStorage.getItem('store');
      if (savedState) {
        this.replaceState(Object.assign(state, JSON.parse(savedState)));
      }
    },

    UPDATE_METAMASK_LOGIN(state, payload) {
      state.address = payload.address;
      state.verified = payload.verified;
    },

    UPDATE_ADDRESS(state, payload) {
      state.address = payload;
    },

    UPDATE_IS_LOGGED_IN(state, payload) {
      state.verified = payload;
    },

    UPDATE_PVFD_TOKEN_IDS(state, payload) {
      state.pvfdIds = payload;
    },

    SAVE_DOT_EVOLVE_SELECTIONS(state, payload) {
      state.selections[payload.address.toLowerCase()] = {
        burns: payload.burns,
        evolves: payload.evolves,
        benchwarmers: payload.benchwarmers
      };
    },

    RESET_DOT_EVOLVE_SELECTIONS(state, payload) {
      if (!state.selections[payload.address.toLowerCase()]) {
        return;
      }

      state.selections[payload.address] = {
        burns: [],
        evolves: [],
        benchwarmers: []
      };
    },

    LOG_OUT(state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    }
  },

  actions: {
    updateMetamaskAuthentication({ commit }, payload) {
      commit('UPDATE_METAMASK_LOGIN', payload);
    },

    updateAddress({ commit }, payload) {
      commit('UPDATE_ADDRESS', payload);
    },

    updateIsLoggedIn({ commit }, payload) {
      commit('UPDATE_IS_LOGGED_IN', payload);
    },

    updatePvfdTokenIds({ commit }, payload) {
      commit('UPDATE_PVFD_TOKEN_IDS', payload);
    },

    saveDotEvolveSelections({ commit }, payload) {
      commit('SAVE_DOT_EVOLVE_SELECTIONS', payload);
    },

    resetDotEvolveSelections({ commit }, payload) {
      commit('RESET_DOT_EVOLVE_SELECTIONS', payload);
    },

    logout({ commit }) {
      commit('LOG_OUT');
    }
  }
});

/**
 * Integrate vuex with localStorage for specific state changes.
 *
 * TODO: may be worthwhile to keep track of their last wallet.
 */
store.subscribe((mutation, state) => {
  console.log('[store.subscribe] Triggered', {
    // { "type": "UPDATE_PVFD_TOKEN_IDS", "payload": { "tokens": [] } }
    mutation,
    state
  });

  // skip if this isn't a selection update
  if (![
    'SAVE_DOT_EVOLVE_SELECTIONS', 'RESET_DOT_EVOLVE_SELECTIONS',
    'UPDATE_METAMASK_LOGIN', 'UPDATE_ADDRESS', 'UPDATE_IS_LOGGED_IN', 'LOG_OUT'
  ].includes(mutation.type)) {
    return;
  }

  console.log('[store.subscribe] Saving update(s)', {
    address: state.address,
    verified: state.verified,
    selections: state.selections
  });

  const store = {
      address: state.address,
      verified: state.verified,
      selections: state.selections
  };

  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(store));
});

export default store;