<template>
    <div id="app">
        <NavComponent></NavComponent>
        <MainComponent></MainComponent>
        <FooterComponent></FooterComponent>
    </div>
</template>

<script>
    import NavComponent from './components/Nav';
    import MainComponent from './components/Main';
    import FooterComponent from './components/Footer';

    export default {
        name: 'app',
        components: {
            NavComponent,
            MainComponent,
            FooterComponent
        },

        mounted() {
            this.$eventHub.$on('manual-connect', this.manual);
            this.$eventHub.$on('metamask-connect', this.connect);
            this.$eventHub.$on('metamask-disconnect', this.disconnect);
        },

        data() {
            return {
                showAccordion: false
            };
        },

        /*
        setup() {
            const store = useStore();

            let address = computed(function() {
                return store.state.address;
            });

            let verified = computed(function() {
                return store.state.verified;
            });

            let pvfdIds = computed(function() {
                return store.state.pvfdIds;
            });

            return {
                address,
                verified,
                pvfdIds,
            };
        },
        */

        methods: {
            /**
             * Manual address.
             *
             * @param address
             * @returns {Promise<void>}
             */
            async manual(address) {
                console.log('[App][manual]');

                this.AccountService.address = address;
                this.AccountService.verified = false;

                this.$store.dispatch('updateMetamaskAuthentication', {
                    address: this.AccountService.address,
                    verified: this.AccountService.verified
                });

                const tokens = await this.PvfdService.getTokensByOwnerAddress(this.AccountService.address);
                if (tokens && tokens.length) {
                    this.$store.dispatch('updatePvfdTokenIds', { tokens });
                } else {
                    this.$store.dispatch('updatePvfdTokenIds', { tokens: [] });
                }
            },

            /**
             * MetaMask connect.
             *
             * @returns {Promise<void>}
             */
            async connect() {
                console.log('[App][connect]');

                const success = await this.AccountService.connect();
                if (success) {
                    this.$store.dispatch('updateMetamaskAuthentication', {
                        address: this.AccountService.address,
                        verified: this.AccountService.verified
                    });

                    const tokens = await this.PvfdService.getTokensByOwnerAddress(this.AccountService.address);
                    if (tokens && tokens.length) {
                        this.$store.dispatch('updatePvfdTokenIds', { tokens });
                    } else {
                        this.$store.dispatch('updatePvfdTokenIds', { tokens: [] });
                    }
                } else {
                    this.$store.dispatch('updateMetamaskAuthentication', {
                        address: null,
                        verified: false
                    });
                }
            },

            /**
             * MetaMask disconnect.
             *
             * @returns {Promise<void>}
             */
            async disconnect() {
                console.log('[App][disconnect]');

                await this.AccountService.disconnect();

                this.$store.dispatch('logout');
            },

            /**
             * Footer accordion.
             */
            toggle() {
                this.showAccordion = !this.showAccordion;
            }
        }
    };
</script>

<style>
    #disclaimer .card-body {
        font-size: 80%;
    }
</style>