import { createWebHistory, createRouter } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        // redirects
        {
            path: '/',
            redirect: '/pips/rarity'
        },
        {
            path: '/inhabitants',
            redirect: '/pips/rarity'
        },
        {
            path: '/inhabitants/traits',
            redirect: '/pips/traits'
        },
        {
            path: '/dots',
            redirect: '/dots/rarity'
        },
        {
            path: '/cores',
            redirect: '/cores/rarity'
        },

        // TODO
        // /pvfd/stats
        // /united-planets/stats

        // routes
        {
            path: '/pips/rarity',
            name: 'pips-rarity',
            component: () => import('./components/inhabitants/InhabitantsRanked')
        },
        {
            path: '/pips/tailor',
            name: 'pips-tailor',
            component: () => import('./components/inhabitants/InhabitantsTailor.vue')
        },
        {
            path: '/pips/traits',
            name: 'pips-traits',
            component: () => import('./components/inhabitants/InhabitantsTraits')
        },
        {
            path: '/dots/rarity',
            name: 'dots-rarity',
            component: () => import('./components/dots/DotsRanked')
        },
        {
            path: '/dots/traits',
            name: 'dots-traits',
            component: () => import('./components/dots/DotsTraits')
        },
        {
            path: '/dots/evolve',
            name: 'dots-evolve',
            component: () => import('./components/dots/DotsEvolveTool')
        },
        {
            path: '/cores/rarity',
            name: 'cores-rarity',
            component: () => import('./components/cores/CoresRanked')
        },
        {
            path: '/cores/traits',
            name: 'cores-traits',
            component: () => import('./components/cores/CoresTraits')
        },
    ],
    // scrollBehavior(to, from, savedPosition) {
    scrollBehavior() {
        return { top: 0 }
    }
});

export default router;

/*
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        // redirects
        {
            path: '/',
            redirect: '/pips/rarity'
        },
        {
            path: '/inhabitants',
            redirect: '/pips/rarity'
        },
        {
            path: '/inhabitants/traits',
            redirect: '/pips/traits'
        },
        {
            path: '/dots',
            redirect: '/dots/rarity'
        },
        {
            path: '/cores',
            redirect: '/cores/rarity'
        },

        // TODO
        // /pvfd/stats
        // /united-planets/stats

        // routes
        {
            path: '/pips/rarity',
            name: 'pips-rarity',
            component: () => import('./components/inhabitants/InhabitantsRanked')
        },
        {
            path: '/pips/traits',
            name: 'pips-traits',
            component: () => import('./components/inhabitants/InhabitantsTraits')
        },
        {
            path: '/dots/rarity',
            name: 'dots-rarity',
            component: () => import('./components/dots/DotsRanked')
        },
        {
            path: '/dots/traits',
            name: 'dots-traits',
            component: () => import('./components/dots/DotsTraits')
        },
        {
            path: '/dots/evolve',
            name: 'dots-evolve',
            component: () => import('./components/dots/DotsEvolveTool')
        },
        {
            path: '/cores/rarity',
            name: 'cores-rarity',
            component: () => import('./components/cores/CoresRanked')
        },
        {
            path: '/cores/traits',
            name: 'cores-traits',
            component: () => import('./components/cores/CoresTraits')
        },
    ],
    // scrollBehavior(to, from, savedPosition) {
    scrollBehavior() {
        return { top: 0 }
    },

});
*/
