<template>
  <div class="container-fluid mt-4">
    <!-- TODO: error -->
    <div class="container" v-if="errorMessage && errorMessage.length">
      <div class="alert alert-danger fade show">
        {{ errorMessage }}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorMessage: ''
    };
  }
}
</script>