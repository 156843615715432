<template>
  <footer class="text-muted pb-4">
    <div class="container-fluid">
      <div class="text-center p-4">
        © 2023 Inhabitants.Tools. All rights reserved.
      </div>

      <!--<div class="accordion accordion-flush" id="disclaimer">-->
      <div id="disclaimer">
        <div class="card bg-dark text-light">
          <div class="card-header pt-1 pb-0" id="headingOne">
            <h5 class="mb-0">
              <button class="btn btn-block btn-link text-light" :class="{ collapsed: !showAccordion }" @click="toggle">
                Disclaimer
              </button>
            </h5>
          </div>

          <div id="collapseOne" class="collapse" :class="{ show: showAccordion }" aria-labelledby="disclaimerCollapse" data-parent="#disclaimer">
            <div class="card-body">
              <div class="accordion-body bg-dark text-light">
                <h5>Trademarks</h5>
                <ul>
                  <li>All product names, trademarks and registered trademarks are property of their respective owners.</li>
                  <li>All company, product and service names used in this website are for identification purposes only.</li>
                  <li>Use of these names, trademarks and brands does not imply endorsement or affiliation.</li>
                </ul>

                <h5>Liabilities and Warranties</h5>
                <p>
                  The material included in this Site has been compiled from a variety of sources and
                  the Site may include technical inaccuracies or typographical errors. Everything on
                  the Site is provided to you “as is” without warranty of any kind, either express or
                  implied, including, but not limited to, the implied warranties of merchantability,
                  fitness for a particular purpose or non-infringement.
                </p>
                <p>
                  Inhabitants.Tools does not warrant that this Site, various services provided through
                  this Site, and any information, software or other material downloaded from this Site,
                  will be uninterrupted, or error-free.
                </p>
                <p>
                  Inhabitants.Tools makes no warranties or representations as to the accuracy of the Site.
                  Inhabitants.Tools assumes no liability or responsibility for any errors or omissions in
                  the content of the Site. Inhabitants.Tools uses all reasonable effort to ensure the
                  accuracy of the Site but reserves the right to change in its sole discretion the Site,
                  in any way and/or at any time, without notice.
                </p>
                <p>
                  Inhabitants.Tools shall not be liable for any special, incidental, or consequential
                  damages, including without limitation, any lost revenues, lost profits, or third party
                  claims resulting from the use or misuse herein, even if Inhabitants.Tools has been
                  advised of the possibility of such damages or for any claim by another party.
                </p>

                <h5>Links to Third Parties</h5>
                <p>
                  The Linked Affiliates are not under the control of Inhabitants.Tools or the Website and Inhabitants.Tools
                  is not responsible for the contents of any Linked Party, including, without limitation
                  to any link contained in a Linked Site, or any changes or updates to a Linked Site.
                  Inhabitants.Tools is not responsible for any form of transmission, whatsoever, received by the
                  User from any Linked Site. Inhabitants.Tools is providing these links to the User only as a
                  convenience, and the inclusion of any link does not imply endorsement by Inhabitants.Tools or
                  the Website of the Linked Sites or any association with its operators or owners
                  including the legal heirs or assigns thereof.
                </p>
                <p>
                  Inhabitants.Tools may or may not endorse any advertiser on its Website in any manner.
                  The Users are requested to verify the accuracy of all information on their own before
                  undertaking any reliance on such information. Inhabitants.Tools is not responsible for
                  any errors, omissions or representations on any Linked Site. If the Website is
                  advertised in a third party Website/ media platform, then, Inhabitants.Tools is not
                  responsible for any type of error/fraudulent activity/ misconduct of that respective
                  third party Website/media platform.
                </p>

                <h5>Trademarks & Logos</h5>
                <p>
                  Inhabitant Tool's logo and all terms and logos marked with a ™ or ®, are Trademarks
                  or registered Trademarks of Inhabitants.Tools. All other Trademarks and logos or
                  registered Trademarks and logos found on the Site or mentioned herein belong to their
                  respective owners. Nothing contained on this Site should be construed as granting, by
                  implication, estoppel, or otherwise, any license or right to use any Trademark
                  displayed on the Site without the written permission of Inhabitants.Tools or such
                  third party that owns the Trademarks displayed on this Site. Inhabitants.Tools will
                  enforce its intellectual property rights to the fullest extent permitted by law.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      errorMessage: '',
      showAccordion: false
    };
  },
  methods: {
    toggle() {
      this.showAccordion = !this.showAccordion;
    }
  }
}
</script>