// import Vue from 'vue';
import { createApp } from 'vue';
import eventBus from './event-bus';

import Jazzicon from 'vue-jazzicon';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Multiselect from 'vue-multiselect';
import App from './App.vue';
import router from './router';
import store from './store';

import AccountService from './services/AccountService';
import PvfdService from './services/PvfdService';

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
// import '@/assets/css/custom.css';

import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/css/custom.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

/*
import dotenv from 'dotenv';
dotenv.config({ path: '.env' });
*/

/*
// OLD WAY
Vue.use(BootstrapVue);
Vue.use(router);

Vue.component('jazzicon', Jazzicon);
Vue.component('multiselect', Multiselect);

Vue.config.productionTip = true;

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
*/

const app = createApp({
  extends: App,
  beforeCreate() {
    this.$store.commit('INITIALIZE_STORE');
  }
});

// don't display warnings
app.config.errorHandler = () => null;
app.config.warnHandler = () => null;

app.config.globalProperties.$eventHub = eventBus;
app.config.globalProperties.AccountService = AccountService;
app.config.globalProperties.PvfdService = PvfdService;

app
  .component('jazzicon', Jazzicon)
  .component('multiselect', Multiselect);

app
  .use(BootstrapVue)
  .use(BootstrapVueIcons)
  .use(router)
  .use(store);

app.mount('#app');