<template>
  <div class="container-fluid bg-dark">
    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
      <div class="navbar-collapse collapse" id="collapseNavbar">
        <router-link to="/" class="navbar-brand">IH Tools</router-link>

        <ul class="navbar-nav mr-auto">
          <b-nav-item-dropdown text="PIPs" left>
            <b-dropdown-item href="/pips/rarity">Rarity</b-dropdown-item>
            <b-dropdown-item href="/pips/traits">Traits</b-dropdown-item>
            <b-dropdown-item href="/pips/tailor">Tailor</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="DOTs" left>
            <b-dropdown-item href="/dots/rarity">Rarity</b-dropdown-item>
            <b-dropdown-item href="/dots/traits">Traits</b-dropdown-item>
            <b-dropdown-item href="/dots/evolve">Evolve Tool</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="Cores" left>
            <b-dropdown-item href="/cores/rarity">Rarity</b-dropdown-item>
            <b-dropdown-item href="/cores/traits">Traits</b-dropdown-item>
          </b-nav-item-dropdown>

          <li class="nav-item">
              <a href="https://app.reboot.gg/?referral=555" class="btn btn-success" target="_blank">
                  Burn Planets/POW/PUNKS for $GG
              </a>
          </li>
        </ul>

        <ul class="navbar-nav ml-auto">
          <li v-if="!verified" class="nav-item">
            <button
              class="btn btn-primary"
              type="button"
              @click.prevent="connect()">
              <img src="/images/metamask.svg" height="24" width="24" alt="Connect via MetaMask" />
              Connect
            </button>
          </li>

          <li v-if="verified" class="nav-item dropdown">
            <b-dropdown>
              <template #button-content>
                <jazzicon :address="walletAddressSeed" :diameter="24" class="inline-block mr-1" />
                {{ shortWalletAddress }}
              </template>
              <b-dropdown-item>
                <a class="dropdown-item" @click.prevent="disconnect()">Disconnect</a>
              </b-dropdown-item>
            </b-dropdown>
          </li>
        </ul>
      </div>

      <button class="navbar-toggler ms-auto" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </nav>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    let address = computed(function() {
      return store.state.address;
    });

    let verified = computed(function() {
      return store.state.verified;
    });

    return {
      address,
      verified
    };
  },

  computed: {
    shortWalletAddress() {
        return `${this.address.substr(0, 6)}...${this.address.substr(-4)}`;
    },
    walletAddressSeed() {
        // const seed = this.ownerAddress?.startsWith('0x')
        //     ? parseInt(this.ownerAddress.slice(2, 10), 16)
        //     : parseInt(this.ownerAddress.slice(0, 10), 16) ?? 0;

        const seed = parseInt(this.address.slice(2, 10), 16);
        console.log(seed);

        // return AccountService.address;
        return `${seed}`;
    },
    isLoggedIn() {
        return this.address && this.address.length && this.verified;
    }
  },

  methods: {
    async connect() {
      return this.$eventHub.$emit('metamask-connect');
    },
    async disconnect() {
      return this.$eventHub.$emit('metamask-disconnect');
    },


  }
}
</script>
