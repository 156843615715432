import Web3 from 'web3';

import PvfdService from './PvfdService';
import DotsService from './DotsService';
import InhabitantsService from './InhabitantsService';

/**
 * @link https://docs.metamask.io/guide/getting-started.html#basic-considerations
 */
class AccountService {

    web3;

    verified;
    address;

    errorCode;

    metaMaskInstalled() {
        return typeof window.ethereum !== 'undefined';
    }

    validateAddress(address) {
        if (!address || !address.length) {
            return false;
        }

        const web3 = new Web3();

        return web3.utils.isAddress(address);
    }

    /**
     * Handle authenticating (verifying) a user owns a wallet via MetaMask.
     *
     * @return {Promise<void>}
     */
    async connect() {
        this.errorCode = null;

        if (!this.metaMaskInstalled()) {
            console.error('[AccountService] MetaMask must be installed.');
        }

        console.log('[AccountService] Connecting...');

        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

            // returns account address
            this.address = accounts[0];
            this.verified = true;

            console.log('[AccountService] Connected', { address: this.address });

            return true;
        } catch (e) {
            console.error('[AccountService][connect] Error', e);

            if (e.code) {
                this.errorCode = e.code;
            }
        }

        this.address = null;
        this.verified = false;
        return false;
    }

    /**
     * @link https://stackoverflow.com/questions/66866817/is-there-any-way-to-initiate-a-disconnect-request-to-the-metamask-wallet
     * @return {Promise<void>}
     */
    async disconnect() {
        if (!this.metaMaskInstalled()) {
            console.error('[AccountService] MetaMask must be installed.');
        }

        if (!this.verified) {
            return;
        }

        try {
            await window.ethereum.request({
                method: "eth_requestAccounts",
                params: [{eth_accounts: {}}]
            });

            this.address = null;
            this.verified = null;
        } catch (e) {
            console.error('[AccountService][disconnect] Error', e);
        }
    }

    /**
     * Is the given address a PVFD holder.
     *
     * @param address
     * @return {*}
     */
    async getPvfdTokenIds(retries = 1) {
        if (typeof window.ethereum === 'undefined') {
            return false;
        }

        try {

            const contract = new this.web3.eth.Contract(PvfdService.CONTRACT_ABI, PvfdService.CONTRACT_ADDRESS);

            // first trigger balanceOf() to get the number of tokens ownerd by an account
            let balance = await contract.methods.balanceOf(this.address).call();
            if (!balance) {
                console.error('[AccountService][getPvfdTokenIds] Unable to retrieve balanceOf', { address: this.address, balance });
                return [];
            }

            console.log('[AccountService][getPvfdTokenIds] Balance', { balance });

            const batch = new this.web3.BatchRequest();

            for (let i = 0; i < Number(balance); i++) {
                // const token_id = await contract.methods.tokenOfOwnerByIndex(this.address, i).call();
                // tokens.push(token_id);
                batch.add(contract.methods.tokenOfOwnerByIndex(this.address, i).call.request({}));
            }

            // const response = await batch.execute();
            const tokens = await this.executeBatchAsync(batch);
            console.log('[AccountService][getPvfdTokenIds] Batch response', { tokens });

            return tokens;

        } catch (e) {
            if (e.code == -32000 && retries < 5) {
                ++retries;
                return this.getPvfdTokenIds(retries);
            }

            console.error('[AccountService][getPvfdTokenIds] Error', e);
        }
    }

    /**
     * Given an address, get the associated DOTs.
     *
     * @link https://etherscan.io/token/0xd07597b64b4878add0965bb1727247ced90c6ce8#readContract
     */
    async getDotTokenIds(retries = 1) {
        if (typeof window.ethereum === 'undefined') {
            return false;
        }

        try {

            const contract = new this.web3.eth.Contract(DotsService.CONTRACT_ABI, DotsService.CONTRACT_ADDRESS);

            const tokens = await contract.methods.tokensOfOwner(this.address).call();
            console.log('[AccountService][getDotTokenIds]', tokens);

            return tokens;

        } catch (e) {
            if (e.code == -32000 && retries < 5) {
                ++retries;
                return this.getDotTokenIds(retries);
            }

            console.error('[AccountService][getDotTokenIds] Error', e);
        }
    }

    /**
     * Get all inhabitants holdings.
     *
     * @return {Promise<*>}
     */
    async getInhabitantTokenIds(retries = 1) {
        if (typeof window.ethereum === 'undefined') {
            return false;
        }

        try {

            const contract = new this.web3.eth.Contract(InhabitantsService.CONTRACT_ABI, InhabitantsService.CONTRACT_ADDRESS);

            // first trigger balanceOf() to get the number of tokens ownerd by an account
            let balance = await contract.methods.balanceOf(this.address).call();
            if (!balance) {
                console.error('[AccountService][getInhabitantTokenIds] Unable to retrieve balanceOf', { address: this.address, balance });
                return [];
            }

            console.log('[AccountService][getInhabitantTokenIds] Balance', { balance });

            const batch = new this.web3.BatchRequest();

            // then call tokenOfOwnerByIndex() to get each owned token id
            for (let i = 0; i < Number(balance); i++) {
                // const token_id = await contract.methods.tokenOfOwnerByIndex(this.address, i).call();
                // tokens.push(token_id);
                batch.add(contract.methods.tokenOfOwnerByIndex(this.address, i).call.request({}));
            }

            const tokens = await this.executeBatchAsync(batch);
            console.log('[AccountService][getInhabitantTokenIds] Batch response', { tokens });

            return tokens;

        } catch (e) {
            if (e.code == -32000 && retries < 5) {
                ++retries;
                return this.getInhabitantTokenIds(retries);
            }

            console.error('[AccountService][getInhabitantTokenIds] Error', e);
        }
    }

    async executeBatchAsync (batch) {
        return new Promise((resolve, reject) => {
            const requests = batch.requests;

            batch.requestManager.sendBatch(requests, (err, results) => {
                results = results || [];

                const response = requests.map((request, index) => {
                    return results[index] || {};
                }).map((result, index) => {
                    if (result && result.error) {
                        console.error(result);
                        reject();
                        return false;
                    }

                    /*
                    if (!Jsonrpc.isValidResponse(result)) {
                        return errors.InvalidResponse(result);
                    }
                    */

                    return requests[index].format ? requests[index].format(result.result) : result.result;
                });

                resolve(response);
            });
        });
    }

}

export default new AccountService();